import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/action-cards/ActionCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/HeroBannerSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/HomeAlert.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArrowRight"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/Icons.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/components/image-card/ImageCardCapsuleCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/components/image-card/ImageCardCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/product-card/ProductCardCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/range/ProductRangeCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/sections/VideoSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/components/SupplierList.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/styles/components/card-carousel.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/styles/components/image-card-grid.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/styles/components/general-content-component.scss");
